/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f7fa;
  color: #333;
}

.saas-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.header .logo {
  font-size: 24px;
  font-weight: bold;
  color: #0056b3;
}

.header nav a {
  margin-left: 20px;
  text-decoration: none;
  color: #555;
}

.header nav a:hover {
  color: #0056b3;
}

/* Hero Section */
.hero {
  text-align: center;
  padding: 80px 20px;
  background-image: url("../../public/back_3.webp");
  background-size: cover;
  background-position: center;
  color: #fff;
  opacity: 0.9; 
}

.hero h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 20px;
  margin-bottom: 40px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-button, .cta-button-secondary {
  padding: 15px 30px;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-button {
  background-color: #ff6b6b;
  color: #fff;
}

.cta-button:hover {
  background-color: #e85b5b;
}

.cta-button-secondary {
  background-color: #333;
  color: #fff;
}

.cta-button-secondary:hover {
  background-color: #555;
}

/* Tools Section */
.tools-section {
  display: flex;
  justify-content: space-around;
  padding: 48px 0;
  gap: 16px;
  background-color: #fff;
}

.tool {
  width: 45%;
  text-align: center;
}

.tool h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #0056b3;
}

.tool p {
  font-size: 18px;
  margin-bottom: 40px;
}

.tool .cta-button, .tool .cta-button-secondary {
  padding: 12px 25px;
}

/* Signup Section */
.signup-section {
  text-align: center;
  padding: 60px 20px;
  background-color: #f0f0f0;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  /* Tablet */
  .hero h1 {
      font-size: 36px;
  }

  .hero p {
      font-size: 18px;
  }

  .tools-section {
      flex-direction: column;
      align-items: center;
  }

  .tool {
      width: 80%;
      margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  /* Mobile */
  .header {
      flex-direction: column;
      align-items: flex-start;
  }

  .header nav {
      margin-top: 10px;
  }

  .header nav a {
      margin-left: 0;
      margin-right: 10px;
  }

  .hero {
      padding: 60px 20px;
  }

  .hero h1 {
      font-size: 28px;
  }

  .hero p {
      font-size: 16px;
  }

  .cta-buttons {
      flex-direction: column;
      gap: 10px;
  }

  .cta-button, .cta-button-secondary {
      padding: 12px 20px;
      font-size: 14px;
  }

  .tools-section {
      padding: 40px 0;
  }

  .tool {
      width: 100%;
      margin-bottom: 30px;
  }

  .tool h2 {
      font-size: 24px;
  }

  .tool p {
      font-size: 16px;
  }

  .signup-section {
      padding: 40px 20px;
  }
}
