body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
  }
  
  .landing-page {
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #1f1f1f;
  }
  
  .brand-name {
    font-size: 28px;
    color: #fff;
  }
  
  .nav-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .nav-links li {
    margin-left: 20px;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .hero {
    text-align: center;
    background-image: url('https://source.unsplash.com/1600x900/?casino');
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 80px 20px;
    margin-bottom: 40px;
  }
  
  .hero-content h2 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 20px;
    max-width: 700px;
    margin: 0 auto 40px;
  }
  
  .cta-button {
    background-color: #ff5722;
    color: #fff;
    padding: 15px 30px;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #e64a19;
  }
  
  .tools {
    display: flex;
    justify-content: space-around;
    margin-bottom: 60px;
  }
  
  .tool {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    width: 45%;
  }
  
  .tool h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .tool p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .footer {
    text-align: center;
    padding: 20px 0;
    background-color: #1f1f1f;
    color: #fff;
  }
  
  .footer-link {
    color: #ff5722;
    text-decoration: none;
    font-size: 16px;
  }
  