/* components/Home.css */

/* Landing Page Styles */
.landing {
    /* background-color: #2a2a72; */
    background-image: url("../../public/background.png"); /* linear-gradient(315deg, #2a2a72 0%, #009ffd 74%); */
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .landing-content {
    text-align: center;
    color: #ffffff;
  }
  
  .landing-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .landing-description {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    display: inline-block;
    background-color: #ff4500; /* Custom primary color */
    color: #ffffff;
    font-size: 2rem;
    padding: 20px 80px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ff6300; /* Custom hover color */
  }
  