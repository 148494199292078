/* Landing Page Styles */
.iframe-grid {
  /* background-color: #2a2a72; 
  background-image: url("../../public/background.png");
  background-size: cover;
  background-position: center;*/
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}